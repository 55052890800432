import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'payments',
  getters: {
	all: state => Object.values(state.documents).sort((a,b) => {
		if(a.date && b.date){
			return Date.parse(b.date) - Date.parse(a.date)
		}
		if(a.createdAt && b.createdAt){
			return Date.parse(b.createdAt) - Date.parse(a.createdAt)
		}
		// anything without a date should go to the end
		return -1
	}),
	forCollab: (state, getters) => collabId => {
		return getters.all.filter(object => object.collab === collabId || false)
	},
	forSelectedAccount: (state, getters, rootState, rootGetters) => {
		return getters.all.filter(payment => payment.brand === rootGetters['account/selected']._id)
	}
  },
  async beforeSave (context, doc) {

  },
})
