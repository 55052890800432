import axios from 'axios'
import deepmerge from 'deepmerge';
import Vue from 'vue'
import { Module } from 'vuex';



import { BaseModuleState, BaseDocument } from "@/store/base-module.d";
import { RootState } from '@/store/index';

const handle = (promise) => {
	// throw error if promise is not a Promise
	if (!(promise instanceof Promise)) {
		throw new Error('Vuex lifecycle hook must return a promise. Use async or return a Promise.')
	}
	return promise
		.then(data => ([data, undefined]))
		.catch(error => Promise.resolve([undefined, error]))
}

export default function (config): Module<BaseModuleState, RootState> {
	const moduleApi = config.api || config.name


	const interceptError = (error, action) => {

		try{
			// Make sure this is an error from the server
			const message = error.response.data.message;
			window.app.$store.commit('app/SET_ACTION_ERROR', {
				module: moduleApi,
				action,
				error
			})
		} catch(e) {
			console.error(e, error);
		}
	}
	function documentIsOutdated(state, object) {
		// Input validation
		if (!object || typeof object !== 'object' || !object._id || !object.updatedAt) {
			console.warn('Invalid object passed to documentIsOutdated:', object);
			return false; // We can't determine if it's outdated, so we'll assume it's not
		}
	
		const existingDoc = state.documents[object._id];
	
		if (existingDoc && existingDoc.updatedAt) {
			try {
				const existingDocUpdatedAt = new Date(existingDoc.updatedAt).getTime();
				const newObjectUpdatedAt = new Date(object.updatedAt).getTime();
	
				if (newObjectUpdatedAt < existingDocUpdatedAt) {
					console.warn(
						`Object ${object._id} will not be set because it is older than the current version`,
						{ incoming: object, existing: existingDoc }
					);
					return true;
				}
			} catch (error) {
				console.error('Error comparing dates in documentIsOutdated:', error);
				return false; // In case of error, we'll assume it's not outdated to be safe
			}
		}
	
		return false; // document is not outdated compared to existing records
	}
	const module: Module<BaseModuleState, RootState> = {
		namespaced: true,
		state: {
			documents: {},
			lastLoadDate: 0,
			lastLoadDateLocal: 0,
		},
		mutations: {
			ON_DEAUTHENTICATE(state) {
				state.documents = {}
				state.lastLoadDate = 0;
				state.lastLoadDateLocal = 0;
				if (config.onDeauthenticate) {
					config.onDeauthenticate(state)
				}
			},
			SET(state, object) {
				if(documentIsOutdated(state, object)){
					return // Abort if the document is outdated
				}
				if (object.deletedAt) {
					Vue.delete(state.documents, object._id)
					return;
				}
				Vue.set(state.documents, object._id, object)
			},
			UPDATE(state, object) {
				if(documentIsOutdated(state, object)){
					return // Abort if the document is outdated
				}
				if (object.deletedAt) {
					Vue.delete(state.documents, object._id)
					return;
				}
				Vue.set(
					state.documents,
					object._id,
					deepmerge(
						state.documents[object._id] || {},
						object,
						{
							customMerge: (key) => {
								return (a, b) => {
									// if b is an empty object, use b
									if (Object.keys(b).length === 0 && b.constructor === Object) {
										// console.log('key', key, 'is an empty object. returning b:', b);
										return b;
									}
									// if the key is an array, use b
									if (Array.isArray(b)) {
										// console.log('key', key, 'is an array. returning b:', b);
										return b;
									}
									// otherwise, default merging
									return deepmerge(a, b);
								}
							}
						}
					)
				)
			},
			CREATE_BULK(state, objects) {
				if (Array.isArray(objects)) {
					const newDocuments = {};
					for (let i = 0; i < objects.length; i++) {
						if (objects[i].deletedAt) {
							Vue.delete(state.documents, objects[i]._id)
							continue;
						}
						newDocuments[objects[i]._id] = objects[i];
					}
					state.documents = { ...state.documents, ...newDocuments };
				}
			},
			DELETE(state, id) {
				Vue.delete(state.documents, id)
			},
			SET_LAST_LOAD_DATE(state, value) {
				state.lastLoadDate = value;
				state.lastLoadDateLocal = Date.now();
			},
			// This is used when cache is invalidated
			// so fresh data can be loaded from the server
			RESET_LAST_LOAD_DATE(state) {
				state.lastLoadDate = 0;
				state.lastLoadDateLocal = 0;
			},
			RESTORE(state, storedState) {
				// delete deleted documents
				const documents: BaseDocument[] = Object.values(storedState.documents)
				for (const document of documents) {
					if (document.deletedAt) {
						delete storedState.documents[document._id]
					}
				}
				// loop over keys setting state
				for (const key of Object.keys(storedState)) {
					state[key] = storedState[key];
				}
			}
		},
		actions: {
			async load(context) {
				try {
					const response = await axios.get(`/api/${moduleApi}?since=${context.getters.lastLoadDate || 0}`)
					if (response && response.data) {
						context.commit('CREATE_BULK', response.data)
						context.commit('SET_LAST_LOAD_DATE', Number(response.headers['x-server-time']));
						if (config.afterLoad) {
							await handle(config.afterLoad(context))
						}
						return response.data;
					}
				} catch (e) {
					interceptError(e, 'load')
					return e;
				}
			},
			async periodicalReload(context) {
				if (context.rootState.app.documentCacheTTL) {
					// if the vuex store has been loaded more recently than the cache TTL, don't reload
					if (Date.now() - context.state.lastLoadDateLocal > context.rootState.app.documentCacheTTL) {
						return context.dispatch('load');
					} else {
						console.debug('Data is fresh');
					}
				} else {
					console.warn('app.documentCacheTTL is not set. This is probably a bug.');
				}
			},
			async create(context, object) {
				try {
					if (config.beforeSave) {
						const [success, error] = await handle(config.beforeSave(context, object))
						if (error) {
							throw error;
						}
					}
					const response = await axios.post(`/api/${moduleApi}`, object)
					if (response && response.data) {
						context.commit('SET', response.data)
						if (config.onChange) {
							await handle(config.onChange(context, response.data))
						}
						if (config.onCreate) {
							await handle(config.onCreate(context, response.data))
						}
						return response.data
					}
				} catch (e) {
					interceptError(e, 'create')
					throw e;
				}
			},
			async read(context, id) {
				try {
					const response = await axios.get(`/api/${moduleApi}/${id}`)
					if (response && response.data) {
						context.commit('SET', response.data)
						return response.data
					}
				} catch (e) {
					interceptError(e, 'read')
					return e
				}
			},
			async update(context, object) {
				try {
					if (config.beforeSave) {
						await handle(config.beforeSave(context, object))
					}
					const response = await axios.put(`/api/${moduleApi}/${object._id}`, object)
					if (response && response.data) {
						if (config.onChange) {
							await handle(config.onChange(context, response.data))
						}
						if (config.onUpdate) {
							await handle(config.onUpdate(context, response.data))
						}
						context.commit('SET', response.data)
						return response.data
					}
				} catch (e) {
					interceptError(e, 'update')
					return e;
				}
			},
			async delete(context, id) {
				const document = context.state.documents[id];
				try {
					if (config.beforeDelete) {
						await handle(config.beforeDelete(context, document))
					}
					const response = await axios.delete(`/api/${moduleApi}/${id}`)
					if (response && response.data) {
						if (config.onDelete) {
							await handle(config.onDelete(context, document))
						}
						context.commit('DELETE', id)
						return true;
					}
				} catch (e) {
					interceptError(e, 'delete')
					return e;
				}
			},
			hasAny: async function (context) {
				if (context.getters.all.length > 0) {
					return true;
				}
				await context.dispatch('load');
				return context.getters.all.length > 0;
			}
		},
		getters: {
			lastLoadDate: (state, getters, rootState) => {
				// if the state has a lastLoadDate, that means it has cached data
				// so if the server has restarted since then, we need to reload
				if (state.lastLoadDate) {
					return Math.max(rootState.app.serverStartTime, state.lastLoadDate)
				}
				// if the state DOESN'T have a last load date, that means it's empty, so we want to get stuff from scratch
				return 0;
			},
			all: state => Object.values(state.documents),
			byId: state => id => state.documents[id] || null,
			bySlug: (state, getters) => slug => getters.all.find(object => object.slug === slug) || null,
			whereId: state => ids => {
				const docs = [];
				for (const id of ids) {
					const doc = state.documents[id];
					if (doc) {
						docs.push(doc);
					}
				}
				return docs;
			},
			forSelectedAccount: (state, getters, rootstate, rootGetters) => {
				const account = rootGetters['account/selected']
				return getters.all.filter(object => [object.account, object.brand, object.creator].includes(account._id) || false)
			}
		}
	}

	// if module.getters.ON_DEAUTHENTICATE is defined throw an error
	if (config.mutations && config.mutations.ON_DEAUTHENTICATE) {
		throw new Error(`BaseModule: ON_DEAUTHENTICATE is a reserved getter name. use onDeauthenticate() hook on module ${moduleApi}`)
	}

	Object.assign(module.state, config.state || {})
	Object.assign(module.mutations, config.mutations || {})
	Object.assign(module.actions, config.actions || {})
	Object.assign(module.getters, config.getters || {})



	return module
}
