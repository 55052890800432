import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import profile from '@/plugins/profile';
import vuexWebsocket from '@/plugins/vuex-socket'
import vuexSyncDocuments from '@/plugins/vuex-sync-documents'
import vuexCreatorVision from '@/plugins/vuex-creator-vision';
import account from '@/store/account';
import { AccountState } from '@/store/account.d';
import address from "@/store/address";
import agreement from "@/store/agreement";
import agreementDeliverable from "@/store/agreement-deliverable";
import agreementPaymentTerm from "@/store/agreement-payment-term";
import agreementShipment from "@/store/agreement-shipment";
import app from '@/store/app'
import appConfig from '@/store/app-config';
import { AppState } from '@/store/app.d';
import collab from '@/store/collab'
import collaboration from '@/store/collaboration'
import countries from '@/store/countries'
import media from "@/store/media";
import { MediaState } from "@/store/media.d";
import payment from '@/store/payment'
import message from "@/store/message";
import note from "@/store/note";
import notification from '@/store/notification'
import objectEventSubscription from "@/store/object-event-subscription";
import plan from '@/store/plan'
import project from '@/store/project'
import service from '@/store/service'
import socialEntity from '@/store/social-entity'
import { SocialEntityState } from '@/store/social-entity.d';
import socket from '@/store/socket'
import subscriber from "@/store/subscriber";
import subscription from '@/store/subscription'
import systemEvent from '@/store/system-event'
import systemEventType from '@/store/system-event-type'
import taxDocument from "@/store/tax-document";
import user from '@/store/user'
import creatorVision from '@/store/creator-vision'



export interface RootState {
  app: AppState;
  account: AccountState;
  media: MediaState;
  socialEntity: SocialEntityState;
}

axios.defaults.withCredentials = true

const modules = {
  app,
  appConfig,
  user,
  account,
  agreement,
  agreementDeliverable,
  agreementPaymentTerm,
  agreementShipment,
  socialEntity,
  collaboration,
  notification,
  systemEventType,
  project,
  plan,
  countries,
  service,
  media,
  subscriber,
  taxDocument,
  note,
  payment,
  collab,
  message,
  systemEvent,
  objectEventSubscription,
  address,
  subscription,
  socket,
  creatorVision
};


if(import.meta.env.NODE_ENV !== 'production') {
  // ADD PERFORMANCE PROFILING
  const addProfiling = (fn, key) => (...args) => profile(key)(fn(...args));
  Object.keys(modules).forEach(name => {
    ['getters', 'actions', 'mutations'].forEach(type => {
      if(modules[name][type]){
        Object.keys(modules[name][type]).forEach(method =>
          modules[name][type][method] = addProfiling(
            modules[name][type][method],
            `${name}:${method}`
          )
        )
      }
    });
  });
}

const selectedAccountStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  modules: ['account','app'],
  reducer: (state: RootState) => ({
    account: {
      selectedId: state.account.selectedId
    },
    app: {
      tours: state.app.tours
    }
  }),
  filter: (mutation) => [
    'account/SET_CREATOR_FILTER_SETTINGS',
    'account/SET_SELECTED_ACCOUNT',
    'app/SET_TOUR_STATUS'
  ].includes(mutation.type)
})


Vue.use(Vuex)
const store = new Vuex.Store({
  plugins: [
    selectedAccountStorage.plugin,
    vuexSyncDocuments,
    vuexWebsocket,
	vuexCreatorVision
  ],
  modules
})

// =================
// When accounts are loaded
// Automatically select first one
// =================
store.watch(
  (state, getters) => getters['account/forUser'],
  accounts => {
    if (accounts.length > 0) {
      if (!store.state.account.selectedId) {
        // No account is currently selected.
        // Select the first account
        store.commit('account/SET_SELECTED_ACCOUNT', accounts[0]._id)
      } else {
        // An account is selected.
        // Confirm the account exists
        const accountExists = accounts.find(account => {
          return account._id === store.state.account.selectedId
        })
        if (!accountExists) {
          // Account doesn't exist
          // Select the first account they have access to
          store.commit('account/SET_SELECTED_ACCOUNT', accounts[0]._id)
        }
      }
    } else {
      // This user has no accounts
      // They probably haven't loaded yet
      // store.commit('account/SET_SELECTED_ACCOUNT', null)
    }
  }
)
// Commit all module/ON_DEAUTHENTICATE mutations
// when user de-authenticates
store.watch(
  (state, getters) => getters['user/isAuthenticated'],
  isAuthenticated => {
    if (!isAuthenticated) {
      Object.keys((store as any)._mutations).filter(mutationName => {  // eslint-disable-line
        return mutationName.includes('ON_DEAUTHENTICATE')
      }).forEach(mutationName => {
        store.commit(mutationName)
      })
    }
  }
)


// Watch for plan upgrades
store.watch(
    (state, getters) => getters['subscription/features'],
    () => {
      store.commit('app/SET_UPGRADE_MODAL_REASON', '')
    }
)

export default store
